@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  &::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    display: none;
    width: 0px;
  }
}

:root,
.light,
.light-theme {
  --blue-1: #fdfdfe;
  --blue-2: #f7f9ff;
  --blue-3: #edf2fe;
  --blue-4: #dfeaff;
  --blue-5: #d0dfff;
  --blue-6: #bdd1ff;
  --blue-7: #a6bff9;
  --blue-8: #87a5ef;
  --blue-9: #3d63dd;
  --blue-10: #3657c3;
  --blue-11: #395bc7;
  --blue-12: #1d2e5c;

  --blue-a1: #00008002;
  --blue-a2: #0040ff08;
  --blue-a3: #0047f112;
  --blue-a4: #0058ff20;
  --blue-a5: #0052ff2f;
  --blue-a6: #004eff42;
  --blue-a7: #0048ee59;
  --blue-a8: #0040dd78;
  --blue-a9: #0032d2c2;
  --blue-a10: #002ab3c9;
  --blue-a11: #002cb7c6;
  --blue-a12: #001347e2;

  --blue-contrast: #fff;
  --blue-surface: #f5f8ffcc;
  --blue-indicator: #3d63dd;
  --blue-track: #3d63dd;
  --gray-1: #fcfcfd;
  --gray-2: #f9f9fb;
  --gray-3: #eff0f3;
  --gray-4: #e7e8ec;
  --gray-5: #e0e1e6;
  --gray-6: #d8d9e0;
  --gray-7: #cdced7;
  --gray-8: #b9bbc6;
  --gray-9: #8b8d98;
  --gray-10: #80828d;
  --gray-11: #62636c;
  --gray-12: #1e1f24;

  --gray-a1: #00005503;
  --gray-a2: #00005506;
  --gray-a3: #00104010;
  --gray-a4: #000b3618;
  --gray-a5: #0009321f;
  --gray-a6: #00073527;
  --gray-a7: #00063332;
  --gray-a8: #00083046;
  --gray-a9: #00051d74;
  --gray-a10: #00051b7f;
  --gray-a11: #0002119d;
  --gray-a12: #000107e1;

  --gray-contrast: #ffffff;
  --gray-surface: #ffffffcc;
  --gray-indicator: #8b8d98;
  --gray-track: #8b8d98;

  /* Origin */
  --origin-primary: #3451b2;
  --origin-secondary: #f0f4ff;
  --origin-tertiary: #f8faff;

  /* Neutral */
  --neutral-primary: #211f26;
  --neutral-secondary: #817f8b;
  --neutral-tertiary: #bcbac7;
  --neutral-light-grey: #eceaef;
  --neutral-background: #ffffff;

  /* System */
  --system-error: #e5484d;
  --system-success: #299764;
  --system-pending: #d6a35c;
  --system-error-light: #e5484d;
  --system-success-light: #f2fcf5;
  --system-pending-light: #fff9ed;

  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) rgba(255, 255, 255, 0.5);
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root,
    .light,
    .light-theme {
      --blue-1: oklch(99.4% 0.0015 266.8);
      --blue-2: oklch(98.3% 0.008 266.8);
      --blue-3: oklch(96.1% 0.0172 266.8);
      --blue-4: oklch(93.5% 0.0338 266.8);
      --blue-5: oklch(90.3% 0.0512 266.8);
      --blue-6: oklch(86.3% 0.0714 266.8);
      --blue-7: oklch(80.7% 0.0869 266.8);
      --blue-8: oklch(73% 0.1134 266.8);
      --blue-9: oklch(54.3% 0.1913 266.8);
      --blue-10: oklch(49.7% 0.1729 266.8);
      --blue-11: oklch(51% 0.1729 266.8);
      --blue-12: oklch(31.3% 0.0856 266.8);

      --blue-a1: color(display-p3 0.0196 0.0196 0.5098 / 0.008);
      --blue-a2: color(display-p3 0.0196 0.2667 0.8784 / 0.032);
      --blue-a3: color(display-p3 0.0078 0.2392 0.8863 / 0.067);
      --blue-a4: color(display-p3 0.0039 0.3059 0.9373 / 0.118);
      --blue-a5: color(display-p3 0.0039 0.2784 0.9333 / 0.173);
      --blue-a6: color(display-p3 0.0039 0.2471 0.9373 / 0.244);
      --blue-a7: color(display-p3 0.0039 0.2314 0.8706 / 0.33);
      --blue-a8: color(display-p3 0.0039 0.2039 0.8078 / 0.448);
      --blue-a9: color(display-p3 0 0.1529 0.7725 / 0.726);
      --blue-a10: color(display-p3 0 0.1294 0.6549 / 0.761);
      --blue-a11: color(display-p3 0 0.1333 0.6667 / 0.746);
      --blue-a12: color(display-p3 0 0.0588 0.251 / 0.871);

      --blue-contrast: #fff;
      --blue-surface: color(display-p3 0.9608 0.9725 1 / 0.8);
      --blue-indicator: oklch(54.3% 0.1913 266.8);
      --blue-track: oklch(54.3% 0.1913 266.8);
      --gray-1: oklch(99.1% 0.0015 277.7);
      --gray-2: oklch(98.2% 0.003 277.7);
      --gray-3: oklch(95.6% 0.0045 277.7);
      --gray-4: oklch(93.1% 0.0061 277.7);
      --gray-5: oklch(91% 0.0077 277.7);
      --gray-6: oklch(88.8% 0.0093 277.7);
      --gray-7: oklch(85.3% 0.0117 277.7);
      --gray-8: oklch(79.4% 0.016 277.7);
      --gray-9: oklch(64.6% 0.0165 277.7);
      --gray-10: oklch(61% 0.0161 277.7);
      --gray-11: oklch(50.3% 0.0139 277.7);
      --gray-12: oklch(24.1% 0.0099 277.7);

      --gray-a1: color(display-p3 0.0235 0.0235 0.349 / 0.012);
      --gray-a2: color(display-p3 0.0235 0.0235 0.349 / 0.024);
      --gray-a3: color(display-p3 0.0078 0.0667 0.2549 / 0.063);
      --gray-a4: color(display-p3 0.0118 0.051 0.2157 / 0.095);
      --gray-a5: color(display-p3 0.0039 0.0392 0.2 / 0.122);
      --gray-a6: color(display-p3 0.0039 0.0275 0.1804 / 0.153);
      --gray-a7: color(display-p3 0.0078 0.0275 0.1843 / 0.197);
      --gray-a8: color(display-p3 0.0039 0.0314 0.1765 / 0.275);
      --gray-a9: color(display-p3 0.0039 0.0196 0.1059 / 0.455);
      --gray-a10: color(display-p3 0.0039 0.0196 0.098 / 0.499);
      --gray-a11: color(display-p3 0 0.0078 0.0588 / 0.616);
      --gray-a12: color(display-p3 0 0.0039 0.0275 / 0.883);

      --gray-contrast: #ffffff;
      --gray-surface: color(display-p3 1 1 1 / 80%);
      --gray-indicator: oklch(64.6% 0.0165 277.7);
      --gray-track: oklch(64.6% 0.0165 277.7);
    }
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.ant-btn {
  -webkit-animation: none !important;
  animation: none !important;
}

input,
input::placeholder,
span,
textarea,
h1,
h1,
h3,
h4,
h5 {
  font-family: "Poppins" !important;
}

.country-code {
  .ant-input-number-group-addon {
    background-color: #fff !important;
  }
}
.ant-radio-wrapper {
  display: flex;
  align-items: center;
}

.ant-radio-group {
  display: flex;
}

.ant-radio {
  margin-right: 8px;
}

.tiptap {
  border: 1px solid #d9d9d9;
  padding: 12px;
  border-radius: 4px;
  ul,
  ol {
    padding: 12px;
  }
}

.ant-table-cell {
  ul,
  ol {
    padding: 16px;
  }
}

.rotate-once {
  animation: spin-once 0.6s ease-in-out;
}

@keyframes spin-once {
  100% {
    transform: rotate(360deg);
  }
}

.ant-dropdown-menu-title-content {
  display: flex;
}

.ant-form-item {
  margin-bottom: 32px;
}

.ant-form-item-explain-error {
  margin-top: 4px;
}

/* Superset Dashboard */
#dashboard {
  width: 100%;
  height: 80vh;

  iframe {
    width: 100%;
    height: 94vh;
    border: 0;
  }
}
