@import "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.\!visible {
  visibility: visible !important;
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.bottom-0 {
  bottom: 0;
}

.bottom-4 {
  bottom: 1rem;
}

.bottom-\[30px\] {
  bottom: 30px;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.right-2 {
  right: .5rem;
}

.top-0 {
  top: 0;
}

.z-10 {
  z-index: 10;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: .25rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-\[24px\] {
  margin-top: 24px;
  margin-bottom: 24px;
}

.-mt-3 {
  margin-top: -.75rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-\[12px\] {
  margin-bottom: 12px;
}

.mb-\[16px\] {
  margin-bottom: 16px;
}

.mb-\[60px\] {
  margin-bottom: 60px;
}

.mb-\[80px\] {
  margin-bottom: 80px;
}

.mb-\[82px\] {
  margin-bottom: 82px;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.h-10 {
  height: 2.5rem;
}

.h-16 {
  height: 4rem;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-3 {
  height: .75rem;
}

.h-4 {
  height: 1rem;
}

.h-7 {
  height: 1.75rem;
}

.h-\[50px\] {
  height: 50px;
}

.h-fit {
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.w-1\/2 {
  width: 50%;
}

.w-10 {
  width: 2.5rem;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-3 {
  width: .75rem;
}

.w-4 {
  width: 1rem;
}

.w-48 {
  width: 12rem;
}

.w-6\/12 {
  width: 50%;
}

.w-\[100\%\] {
  width: 100%;
}

.w-\[380px\] {
  width: 380px;
}

.w-\[50px\] {
  width: 50px;
}

.w-\[600px\] {
  width: 600px;
}

.w-\[80\%\] {
  width: 80%;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.min-w-72 {
  min-width: 18rem;
}

.min-w-\[100px\] {
  min-width: 100px;
}

.max-w-20 {
  max-width: 5rem;
}

.max-w-32 {
  max-width: 8rem;
}

.max-w-\[450px\] {
  max-width: 450px;
}

.max-w-\[500px\] {
  max-width: 500px;
}

.max-w-none {
  max-width: none;
}

.flex-1 {
  flex: 1;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-all {
  word-break: break-all;
}

.rounded {
  border-radius: .25rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-sm {
  border-radius: .125rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-\[1px\] {
  border-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-solid {
  border-style: solid;
}

.border-none {
  border-style: none;
}

.border-\[\#ebedf3\] {
  --tw-border-opacity: 1;
  border-color: rgb(235 237 243 / var(--tw-border-opacity, 1));
}

.border-color-primary {
  --tw-border-opacity: 1;
  border-color: rgb(52 81 178 / var(--tw-border-opacity, 1));
}

.border-opacity-20 {
  --tw-border-opacity: .2;
}

.bg-\[var\(--origin-tertiary\)\] {
  background-color: var(--origin-tertiary);
}

.bg-color-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(52 81 178 / var(--tw-bg-opacity, 1));
}

.bg-color-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.object-contain {
  object-fit: contain;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-\[10px\] {
  padding: 10px;
}

.p-\[20px\] {
  padding: 20px;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-\[24px\] {
  padding-top: 24px;
  padding-bottom: 24px;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}

.pb-\[82px\] {
  padding-bottom: 82px;
}

.pl-0 {
  padding-left: 0;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-middle {
  vertical-align: middle;
}

.font-inherit {
  font-family: inherit;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-\[24px\] {
  font-size: 24px;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.text-color-arrow {
  color: #211f2640;
}

.text-color-error {
  --tw-text-opacity: 1;
  color: rgb(229 72 77 / var(--tw-text-opacity, 1));
}

.text-color-primary {
  --tw-text-opacity: 1;
  color: rgb(52 81 178 / var(--tw-text-opacity, 1));
}

.text-color-success {
  --tw-text-opacity: 1;
  color: rgb(82 196 26 / var(--tw-text-opacity, 1));
}

.text-color-text-base {
  --tw-text-opacity: 1;
  color: rgb(33 31 38 / var(--tw-text-opacity, 1));
}

.underline {
  text-decoration-line: underline;
}

.opacity-0 {
  opacity: 0;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

* {
  box-sizing: border-box;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;

  &::-webkit-scrollbar {
    background: none;
    width: 0;
    display: none;
  }
}

:root, .light, .light-theme {
  --blue-1: #fdfdfe;
  --blue-2: #f7f9ff;
  --blue-3: #edf2fe;
  --blue-4: #dfeaff;
  --blue-5: #d0dfff;
  --blue-6: #bdd1ff;
  --blue-7: #a6bff9;
  --blue-8: #87a5ef;
  --blue-9: #3d63dd;
  --blue-10: #3657c3;
  --blue-11: #395bc7;
  --blue-12: #1d2e5c;
  --blue-a1: #00008002;
  --blue-a2: #0040ff08;
  --blue-a3: #0047f112;
  --blue-a4: #0058ff20;
  --blue-a5: #0052ff2f;
  --blue-a6: #004eff42;
  --blue-a7: #0048ee59;
  --blue-a8: #0040dd78;
  --blue-a9: #0032d2c2;
  --blue-a10: #002ab3c9;
  --blue-a11: #002cb7c6;
  --blue-a12: #001347e2;
  --blue-contrast: #fff;
  --blue-surface: #f5f8ffcc;
  --blue-indicator: #3d63dd;
  --blue-track: #3d63dd;
  --gray-1: #fcfcfd;
  --gray-2: #f9f9fb;
  --gray-3: #eff0f3;
  --gray-4: #e7e8ec;
  --gray-5: #e0e1e6;
  --gray-6: #d8d9e0;
  --gray-7: #cdced7;
  --gray-8: #b9bbc6;
  --gray-9: #8b8d98;
  --gray-10: #80828d;
  --gray-11: #62636c;
  --gray-12: #1e1f24;
  --gray-a1: #00005503;
  --gray-a2: #00005506;
  --gray-a3: #00104010;
  --gray-a4: #000b3618;
  --gray-a5: #0009321f;
  --gray-a6: #00073527;
  --gray-a7: #00063332;
  --gray-a8: #00083046;
  --gray-a9: #00051d74;
  --gray-a10: #00051b7f;
  --gray-a11: #0002119d;
  --gray-a12: #000107e1;
  --gray-contrast: #fff;
  --gray-surface: #fffc;
  --gray-indicator: #8b8d98;
  --gray-track: #8b8d98;
  --origin-primary: #3451b2;
  --origin-secondary: #f0f4ff;
  --origin-tertiary: #f8faff;
  --neutral-primary: #211f26;
  --neutral-secondary: #817f8b;
  --neutral-tertiary: #bcbac7;
  --neutral-light-grey: #eceaef;
  --neutral-background: #fff;
  --system-error: #e5484d;
  --system-success: #299764;
  --system-pending: #d6a35c;
  --system-error-light: #e5484d;
  --system-success-light: #f2fcf5;
  --system-pending-light: #fff9ed;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: #0000004d #ffffff80;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  margin: 0;
  padding: 0;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --blue-1: oklch(99.4% .0015 266.8);
      --blue-2: oklch(98.3% .008 266.8);
      --blue-3: oklch(96.1% .0172 266.8);
      --blue-4: oklch(93.5% .0338 266.8);
      --blue-5: oklch(90.3% .0512 266.8);
      --blue-6: oklch(86.3% .0714 266.8);
      --blue-7: oklch(80.7% .0869 266.8);
      --blue-8: oklch(73% .1134 266.8);
      --blue-9: oklch(54.3% .1913 266.8);
      --blue-10: oklch(49.7% .1729 266.8);
      --blue-11: oklch(51% .1729 266.8);
      --blue-12: oklch(31.3% .0856 266.8);
      --blue-a1: #05058802;
      --blue-a2: #004ed808;
      --blue-a3: #0048d911;
      --blue-a4: #0057e61e;
      --blue-a5: #0052e52c;
      --blue-a6: #004be63e;
      --blue-a7: #0046d554;
      --blue-a8: #003fc672;
      --blue-a9: #0034bdb9;
      --blue-a10: #002ca0c2;
      --blue-a11: #002da3be;
      --blue-a12: #00123dde;
      --blue-contrast: #fff;
      --blue-surface: #f5f8ffcc;
      --blue-indicator: oklch(54.3% .1913 266.8);
      --blue-track: oklch(54.3% .1913 266.8);
      --gray-1: oklch(99.1% .0015 277.7);
      --gray-2: oklch(98.2% .003 277.7);
      --gray-3: oklch(95.6% .0045 277.7);
      --gray-4: oklch(93.1% .0061 277.7);
      --gray-5: oklch(91% .0077 277.7);
      --gray-6: oklch(88.8% .0093 277.7);
      --gray-7: oklch(85.3% .0117 277.7);
      --gray-8: oklch(79.4% .016 277.7);
      --gray-9: oklch(64.6% .0165 277.7);
      --gray-10: oklch(61% .0161 277.7);
      --gray-11: oklch(50.3% .0139 277.7);
      --gray-12: oklch(24.1% .0099 277.7);
      --gray-a1: #06065d03;
      --gray-a2: #06065d06;
      --gray-a3: #00134110;
      --gray-a4: #010d3a18;
      --gray-a5: #000b331f;
      --gray-a6: #00082f27;
      --gray-a7: #01073132;
      --gray-a8: #00092d46;
      --gray-a9: #00051c74;
      --gray-a10: #00051a7f;
      --gray-a11: #00020e9d;
      --gray-a12: #000107e1;
      --gray-contrast: #fff;
      --gray-surface: #fffc;
      --gray-indicator: oklch(64.6% .0165 277.7);
      --gray-track: oklch(64.6% .0165 277.7);
    }

    @supports (color: color(display-p3 0 0 0)) {
      :root, .light, .light-theme {
        --blue-a1: color(display-p3 .0196 .0196 .5098 / .008);
        --blue-a2: color(display-p3 .0196 .2667 .8784 / .032);
        --blue-a3: color(display-p3 .0078 .2392 .8863 / .067);
        --blue-a4: color(display-p3 .0039 .3059 .9373 / .118);
        --blue-a5: color(display-p3 .0039 .2784 .9333 / .173);
        --blue-a6: color(display-p3 .0039 .2471 .9373 / .244);
        --blue-a7: color(display-p3 .0039 .2314 .8706 / .33);
        --blue-a8: color(display-p3 .0039 .2039 .8078 / .448);
        --blue-a9: color(display-p3 0 .1529 .7725 / .726);
        --blue-a10: color(display-p3 0 .1294 .6549 / .761);
        --blue-a11: color(display-p3 0 .1333 .6667 / .746);
        --blue-a12: color(display-p3 0 .0588 .251 / .871);
        --blue-surface: color(display-p3 .9608 .9725 1 / .8);
        --gray-a1: color(display-p3 .0235 .0235 .349 / .012);
        --gray-a2: color(display-p3 .0235 .0235 .349 / .024);
        --gray-a3: color(display-p3 .0078 .0667 .2549 / .063);
        --gray-a4: color(display-p3 .0118 .051 .2157 / .095);
        --gray-a5: color(display-p3 .0039 .0392 .2 / .122);
        --gray-a6: color(display-p3 .0039 .0275 .1804 / .153);
        --gray-a7: color(display-p3 .0078 .0275 .1843 / .197);
        --gray-a8: color(display-p3 .0039 .0314 .1765 / .275);
        --gray-a9: color(display-p3 .0039 .0196 .1059 / .455);
        --gray-a10: color(display-p3 .0039 .0196 .098 / .499);
        --gray-a11: color(display-p3 0 .0078 .0588 / .616);
        --gray-a12: color(display-p3 0 .0039 .0275 / .883);
        --gray-surface: color(display-p3 1 1 1 / .8);
      }
    }
  }
}

*, :before, :after {
  box-sizing: border-box;
}

.ant-btn {
  animation: none !important;
}

input, input::placeholder, span, textarea, h1, h1, h3, h4, h5 {
  font-family: Poppins !important;
}

.country-code {
  & .ant-input-number-group-addon {
    background-color: #fff !important;
  }
}

.ant-radio-wrapper {
  align-items: center;
  display: flex;
}

.ant-radio-group {
  display: flex;
}

.ant-radio {
  margin-right: 8px;
}

.tiptap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 12px;

  & ul, & ol {
    padding: 12px;
  }
}

.ant-table-cell {
  & ul, & ol {
    padding: 16px;
  }
}

.rotate-once {
  animation: .6s ease-in-out spin-once;
}

@keyframes spin-once {
  100% {
    transform: rotate(360deg);
  }
}

.ant-dropdown-menu-title-content {
  display: flex;
}

.ant-form-item {
  margin-bottom: 32px;
}

.ant-form-item-explain-error {
  margin-top: 4px;
}

#dashboard {
  width: 100%;
  height: 80vh;

  & iframe {
    border: 0;
    width: 100%;
    height: 94vh;
  }
}

.hover\:text-color-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(52 81 178 / var(--tw-text-opacity, 1));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.group:hover .group-hover\:flex {
  display: flex;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.\[\&_\.ant-radio-wrapper\]\:mb-4 .ant-radio-wrapper {
  margin-bottom: 1rem;
}

.\[\&_\.ant-radio-wrapper\]\:flex .ant-radio-wrapper {
  display: flex;
}
/*# sourceMappingURL=index.aa7411d7.css.map */
